import Axios from "axios";
import Cookies from "js-cookie";
Axios.defaults.timeout = 30000;
// 跨域请求，允许保存cookie
Axios.defaults.withCredentials = false;
Axios.defaults.baseURL = 'http://msi.biyao.com';
Axios.interceptors.request.use(
  config => {
    try {
      config.method = config.isGet? 'GET' : 'POST';
    } catch (error) {
      console.error("axios用户信息处理失败 error=", error);
    }
    if (!config.url.includes("aiapi.biyao.com")) {
      let accountInfo = localStorage.getItem('accountInfo');
      if (accountInfo) {
        accountInfo = JSON.parse(accountInfo);
        config.headers.userId = accountInfo.employee_id;
      }
      config.headers.token = Cookies.get('x-ai-sk') || "";
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);
Axios.interceptors.response.use(
  res => {
    if (!res || res.status != 200) {
      return Promise.reject(res.data.msg || "数据获取失败，请稍后重试！");
    }
    if (res.data.error) {
      res.data.errMsg =  res.data.error.message;
    }
    return res.data;
  },
  error => {
    return Promise.reject(error);
  }
);
const API = Axios;
export default API;